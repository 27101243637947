import { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { registration } from './../../actions/customer'
import { useNavigate } from 'react-router-dom';
import { Input, message, Row } from "antd";

const AdminPage = ({customer}) => {
    const [formData, setFormData] = useState({
        email: customer.data.email,
        password: '',
        last_name: customer.data.last_name,
        first_name: customer.data.first_name,
        company: customer.data.company,
        address1: customer.data.address1,
        address2: customer.data.address2,
        address3: customer.data.address3,
        city: customer.data.city,
        province: customer.data.province,
        post_code: customer.data.post_code,
        country: customer.data.country,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        if (formData.password !== formData.password_repeat) {
            message.error('Passwords do not match');
            return;
        }
        registration(formData);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
       
    };

    return (
        <div>
            <form>

                <div class="row">

                    <div class="large-4 columns">
                        <label>
                            <div class="bh-input-label">First Name <span class="blue">*</span></div>
                        </label>
                        <div class="bh-input">
                            <Input type="text" name="first_name" value={formData.first_name} onChange={handleChange} placeholder="" className="bh-input required" id="tbxFirstName" />
                            {/* <input type="text" placeholder="" class="bh-input required" id="tbxFirstName" /> */}
                        </div>
                        <div class="bh-validation" related="tbxFirstName"></div>
                    </div>

                    <div class="large-4 columns">
                        <label>
                            <div class="bh-input-label">Last Name <span class="blue">*</span></div>
                        </label>
                        <div class="bh-input">
                            <Input  type="text" name="last_name" value={formData.last_name} onChange={handleChange} placeholder="" className="bh-input required" id="tbxLastName" />

                            {/* <input type="text" placeholder="" class="bh-input required" id="tbxLastName" /> */}
                        </div>
                        <div class="bh-validation" related="tbxLastName"></div>
                    </div>

                    <div class="large-4 columns">
                        <label>
                            <div class="bh-input-label">Email <span class="blue">*</span></div>
                        </label>
                        <div class="bh-input">
                            <Input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="" className="bh-input required" id="tbxRequestEmail" />
                            {/* <input type="email" placeholder="" class="bh-input required" id="tbxRequestEmail" /> */}
                        </div>
                        <div class="bh-validation" related="tbxRequestEmail"></div>
                    </div>

                    <div class="large-4 columns">
                        <label>
                            <div class="bh-input-label">Company <span class="blue">*</span></div>
                        </label>
                        <div class="bh-input">
                            <Input  type="text" name="company" value={formData.company} onChange={handleChange} placeholder="" className="bh-input required" id="tbxCompany" />
                            {/* <input type="text" placeholder="" class="bh-input required" id="tbxCompany" /> */}
                        </div>
                        <div class="bh-validation" related="tbxCompany"></div>
                    </div>

                    <div class="large-4 columns">
                        <label>
                            <div class="bh-input-label">Address Line 1 <span class="blue">*</span></div>
                        </label>
                        <div class="bh-input">
                            <Input  type="text" name="address1" value={formData.address1} onChange={handleChange} placeholder="" className="bh-input required" id="tbxAddr1" />

                            {/* <input type="text" placeholder="" class="bh-input required" id="tbxAddr1" /> */}
                        </div>
                        <div class="bh-validation" related="tbxAddr1"></div>
                    </div>

                    <div class="large-4 columns">
                        <label>
                            <div class="bh-input-label">Address Line 2</div>
                        </label>
                        <div class="bh-input">
                            <Input  type="text" name="address2" value={formData.address2} onChange={handleChange} placeholder="" className="bh-input" id="tbxAddr2" />
                            {/* <input type="text" placeholder="" class="bh-input" id="tbxAddr2" /> */}
                        </div>
                        <div class="bh-validation" related="tbxAddr2"></div>
                    </div>

                    <div class="large-4 columns">
                        <label>
                            <div class="bh-input-label">Address Line 3</div>
                        </label>
                        <div class="bh-input">
                            <Input type="text" name="address3" value={formData.address3} onChange={handleChange} placeholder="" className="bh-input" id="tbxAddr3" />

                            {/* <input type="text" placeholder="" class="bh-input" id="tbxAddr3" /> */}
                        </div>
                        <div class="bh-validation" related="tbxAddr3"></div>
                    </div>

                    <div class="large-4 columns">
                        <label>
                            <div class="bh-input-label">City <span class="blue">*</span></div>
                        </label>
                        <div class="bh-input">
                            <Input  type="text" name="city" value={formData.city} onChange={handleChange} placeholder="" className="bh-input required" id="tbxCity" />

                            {/* <input type="text" placeholder="" class="bh-input required" id="tbxCity" /> */}
                        </div>
                        <div class="bh-validation" related="tbxCity"></div>
                    </div>

                    <div class="large-4 columns">
                        <label>
                            <div class="bh-input-label">State / Province / County <span class="blue">*</span></div>
                        </label>
                        <div class="bh-input">
                            <Input  type="text" name="province" value={formData.province} onChange={handleChange} placeholder="" className="bh-input required" id="tbxState" />

                            {/* <input type="text" placeholder="" class="bh-input required" id="tbxState" /> */}
                        </div>
                        <div class="bh-validation" related="tbxState"></div>
                    </div>

                    <div class="large-4 columns">
                        <label>
                            <div class="bh-input-label">ZIP / Post Code<span class="blue">*</span></div>
                        </label>
                        <div class="bh-input">
                            <Input  type="text" name="post_code" value={formData.post_code} onChange={handleChange} placeholder="" className="bh-input required" id="tbxPostCode" />

                            {/* <input type="text" placeholder="" class="bh-input required" id="tbxPostCode" /> */}
                        </div>
                        <div class="bh-validation" related="tbxPostCode"></div>
                    </div>

                    <div class="large-4 columns">
                        <label>
                            <div class="bh-input-label">Country <span class="blue">*</span></div>
                        </label>
                        <div class="bh-input">
                            <select name="country" class="bh-input" id="ddlCountry" value={formData.country} onChange={handleChange}>
                                <option value="" disabled="disabled" selected="selected"></option>
                                <option value="GB">United Kingdom</option>
                                <option value="US">United States</option>
                                <option value="" disabled="disabled">_____________</option>
                                <option value="AF">Afghanistan</option>
                                <option value="AX">Åland Islands</option>
                                <option value="AL">Albania</option>
                                <option value="DZ">Algeria</option>
                                <option value="AS">American Samoa</option>
                                <option value="AD">Andorra</option>
                                <option value="AO">Angola</option>
                                <option value="AI">Anguilla</option>
                                <option value="AQ">Antarctica</option>
                                <option value="AG">Antigua and Barbuda</option>
                                <option value="AR">Argentina</option>
                                <option value="AM">Armenia</option>
                                <option value="AW">Aruba</option>
                                <option value="AU">Australia</option>
                                <option value="AT">Austria</option>
                                <option value="AZ">Azerbaijan</option>
                                <option value="BS">Bahamas</option>
                                <option value="BH">Bahrain</option>
                                <option value="BD">Bangladesh</option>
                                <option value="BB">Barbados</option>
                                <option value="BY">Belarus</option>
                                <option value="BE">Belgium</option>
                                <option value="BZ">Belize</option>
                                <option value="BJ">Benin</option>
                                <option value="BM">Bermuda</option>
                                <option value="BT">Bhutan</option>
                                <option value="BO">Bolivia, Plurinational State of</option>
                                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                <option value="BA">Bosnia and Herzegovina</option>
                                <option value="BW">Botswana</option>
                                <option value="BV">Bouvet Island</option>
                                <option value="BR">Brazil</option>
                                <option value="IO">British Indian Ocean Territory</option>
                                <option value="BN">Brunei Darussalam</option>
                                <option value="BG">Bulgaria</option>
                                <option value="BF">Burkina Faso</option>
                                <option value="BI">Burundi</option>
                                <option value="KH">Cambodia</option>
                                <option value="CM">Cameroon</option>
                                <option value="CA">Canada</option>
                                <option value="CV">Cape Verde</option>
                                <option value="KY">Cayman Islands</option>
                                <option value="CF">Central African Republic</option>
                                <option value="TD">Chad</option>
                                <option value="CL">Chile</option>
                                <option value="CN">China</option>
                                <option value="CX">Christmas Island</option>
                                <option value="CC">Cocos (Keeling) Islands</option>
                                <option value="CO">Colombia</option>
                                <option value="KM">Comoros</option>
                                <option value="CG">Congo</option>
                                <option value="CD">Congo, the Democratic Republic of the</option>
                                <option value="CK">Cook Islands</option>
                                <option value="CR">Costa Rica</option>
                                <option value="CI">Côte d'Ivoire</option>
                                <option value="HR">Croatia</option>
                                <option value="CU">Cuba</option>
                                <option value="CW">Curaçao</option>
                                <option value="CY">Cyprus</option>
                                <option value="CZ">Czech Republic</option>
                                <option value="DK">Denmark</option>
                                <option value="DJ">Djibouti</option>
                                <option value="DM">Dominica</option>
                                <option value="DO">Dominican Republic</option>
                                <option value="EC">Ecuador</option>
                                <option value="EG">Egypt</option>
                                <option value="SV">El Salvador</option>
                                <option value="GQ">Equatorial Guinea</option>
                                <option value="ER">Eritrea</option>
                                <option value="EE">Estonia</option>
                                <option value="ET">Ethiopia</option>
                                <option value="FK">Falkland Islands (Malvinas)</option>
                                <option value="FO">Faroe Islands</option>
                                <option value="FJ">Fiji</option>
                                <option value="FI">Finland</option>
                                <option value="FR">France</option>
                                <option value="GF">French Guiana</option>
                                <option value="PF">French Polynesia</option>
                                <option value="TF">French Southern Territories</option>
                                <option value="GA">Gabon</option>
                                <option value="GM">Gambia</option>
                                <option value="GE">Georgia</option>
                                <option value="DE">Germany</option>
                                <option value="GH">Ghana</option>
                                <option value="GI">Gibraltar</option>
                                <option value="GR">Greece</option>
                                <option value="GL">Greenland</option>
                                <option value="GD">Grenada</option>
                                <option value="GP">Guadeloupe</option>
                                <option value="GU">Guam</option>
                                <option value="GT">Guatemala</option>
                                <option value="GG">Guernsey</option>
                                <option value="GN">Guinea</option>
                                <option value="GW">Guinea-Bissau</option>
                                <option value="GY">Guyana</option>
                                <option value="HT">Haiti</option>
                                <option value="HM">Heard Island and McDonald Islands</option>
                                <option value="VA">Holy See (Vatican City State)</option>
                                <option value="HN">Honduras</option>
                                <option value="HK">Hong Kong</option>
                                <option value="HU">Hungary</option>
                                <option value="IS">Iceland</option>
                                <option value="IN">India</option>
                                <option value="ID">Indonesia</option>
                                <option value="IR">Iran, Islamic Republic of</option>
                                <option value="IQ">Iraq</option>
                                <option value="IE">Ireland</option>
                                <option value="IM">Isle of Man</option>
                                <option value="IL">Israel</option>
                                <option value="IT">Italy</option>
                                <option value="JM">Jamaica</option>
                                <option value="JP">Japan</option>
                                <option value="JE">Jersey</option>
                                <option value="JO">Jordan</option>
                                <option value="KZ">Kazakhstan</option>
                                <option value="KE">Kenya</option>
                                <option value="KI">Kiribati</option>
                                <option value="KP">Korea, Democratic People's Republic of</option>
                                <option value="KR">Korea, Republic of</option>
                                <option value="KW">Kuwait</option>
                                <option value="KG">Kyrgyzstan</option>
                                <option value="LA">Lao People's Democratic Republic</option>
                                <option value="LV">Latvia</option>
                                <option value="LB">Lebanon</option>
                                <option value="LS">Lesotho</option>
                                <option value="LR">Liberia</option>
                                <option value="LY">Libya</option>
                                <option value="LI">Liechtenstein</option>
                                <option value="LT">Lithuania</option>
                                <option value="LU">Luxembourg</option>
                                <option value="MO">Macao</option>
                                <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                <option value="MG">Madagascar</option>
                                <option value="MW">Malawi</option>
                                <option value="MY">Malaysia</option>
                                <option value="MV">Maldives</option>
                                <option value="ML">Mali</option>
                                <option value="MT">Malta</option>
                                <option value="MH">Marshall Islands</option>
                                <option value="MQ">Martinique</option>
                                <option value="MR">Mauritania</option>
                                <option value="MU">Mauritius</option>
                                <option value="YT">Mayotte</option>
                                <option value="MX">Mexico</option>
                                <option value="FM">Micronesia, Federated States of</option>
                                <option value="MD">Moldova, Republic of</option>
                                <option value="MC">Monaco</option>
                                <option value="MN">Mongolia</option>
                                <option value="ME">Montenegro</option>
                                <option value="MS">Montserrat</option>
                                <option value="MA">Morocco</option>
                                <option value="MZ">Mozambique</option>
                                <option value="MM">Myanmar</option>
                                <option value="NA">Namibia</option>
                                <option value="NR">Nauru</option>
                                <option value="NP">Nepal</option>
                                <option value="NL">Netherlands</option>
                                <option value="NC">New Caledonia</option>
                                <option value="NZ">New Zealand</option>
                                <option value="NI">Nicaragua</option>
                                <option value="NE">Niger</option>
                                <option value="NG">Nigeria</option>
                                <option value="NU">Niue</option>
                                <option value="NF">Norfolk Island</option>
                                <option value="MP">Northern Mariana Islands</option>
                                <option value="NO">Norway</option>
                                <option value="OM">Oman</option>
                                <option value="PK">Pakistan</option>
                                <option value="PW">Palau</option>
                                <option value="PS">Palestinian Territory, Occupied</option>
                                <option value="PA">Panama</option>
                                <option value="PG">Papua New Guinea</option>
                                <option value="PY">Paraguay</option>
                                <option value="PE">Peru</option>
                                <option value="PH">Philippines</option>
                                <option value="PN">Pitcairn</option>
                                <option value="PL">Poland</option>
                                <option value="PT">Portugal</option>
                                <option value="PR">Puerto Rico</option>
                                <option value="QA">Qatar</option>
                                <option value="RE">Réunion</option>
                                <option value="RO">Romania</option>
                                <option value="RU">Russian Federation</option>
                                <option value="RW">Rwanda</option>
                                <option value="BL">Saint Barthélemy</option>
                                <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                <option value="KN">Saint Kitts and Nevis</option>
                                <option value="LC">Saint Lucia</option>
                                <option value="MF">Saint Martin (French part)</option>
                                <option value="PM">Saint Pierre and Miquelon</option>
                                <option value="VC">Saint Vincent and the Grenadines</option>
                                <option value="WS">Samoa</option>
                                <option value="SM">San Marino</option>
                                <option value="ST">Sao Tome and Principe</option>
                                <option value="SA">Saudi Arabia</option>
                                <option value="SN">Senegal</option>
                                <option value="RS">Serbia</option>
                                <option value="SC">Seychelles</option>
                                <option value="SL">Sierra Leone</option>
                                <option value="SG">Singapore</option>
                                <option value="SX">Sint Maarten (Dutch part)</option>
                                <option value="SK">Slovakia</option>
                                <option value="SI">Slovenia</option>
                                <option value="SB">Solomon Islands</option>
                                <option value="SO">Somalia</option>
                                <option value="ZA">South Africa</option>
                                <option value="GS">South Georgia and the South Sandwich Islands</option>
                                <option value="SS">South Sudan</option>
                                <option value="ES">Spain</option>
                                <option value="LK">Sri Lanka</option>
                                <option value="SD">Sudan</option>
                                <option value="SR">Suriname</option>
                                <option value="SJ">Svalbard and Jan Mayen</option>
                                <option value="SZ">Swaziland</option>
                                <option value="SE">Sweden</option>
                                <option value="CH">Switzerland</option>
                                <option value="SY">Syrian Arab Republic</option>
                                <option value="TW">Taiwan, Province of China</option>
                                <option value="TJ">Tajikistan</option>
                                <option value="TZ">Tanzania, United Republic of</option>
                                <option value="TH">Thailand</option>
                                <option value="TL">Timor-Leste</option>
                                <option value="TG">Togo</option>
                                <option value="TK">Tokelau</option>
                                <option value="TO">Tonga</option>
                                <option value="TT">Trinidad and Tobago</option>
                                <option value="TN">Tunisia</option>
                                <option value="TR">Turkey</option>
                                <option value="TM">Turkmenistan</option>
                                <option value="TC">Turks and Caicos Islands</option>
                                <option value="TV">Tuvalu</option>
                                <option value="UG">Uganda</option>
                                <option value="UA">Ukraine</option>
                                <option value="AE">United Arab Emirates</option>
                                <option value="UM">United States Minor Outlying Islands</option>
                                <option value="UY">Uruguay</option>
                                <option value="UZ">Uzbekistan</option>
                                <option value="VU">Vanuatu</option>
                                <option value="VE">Venezuela, Bolivarian Republic of</option>
                                <option value="VN">Viet Nam</option>
                                <option value="VG">Virgin Islands, British</option>
                                <option value="VI">Virgin Islands, U.S.</option>
                                <option value="WF">Wallis and Futuna</option>
                                <option value="EH">Western Sahara</option>
                                <option value="YE">Yemen</option>
                                <option value="ZM">Zambia</option>
                                <option value="ZW">Zimbabwe</option>
                            </select>
                        </div>
                        <div class="bh-validation" related="ddlCountry"></div>

                    </div>
                    <div className="large-4 columns">
                        <label>
                            <div className="bh-input-label">Password</div>
                        </label>
                        <div className="bh-input">
                            <Input  type="password" name="password" value={formData.password} onChange={handleChange} placeholder="" className="bh-input required" id="tbxPostCode" />
                        </div>
                        <div className="bh-validation" related="tbxPostCode"></div>
                    </div>
                    <div className="large-4 columns">
                        <label>
                            <div className="bh-input-label">Repeat password</div>
                        </label>
                        <div className="bh-input">
                            <Input  type="password" name="password_repeat" value={formData.password_repeat} onChange={handleChange} placeholder="" className="bh-input required" id="tbxPostCode" />
                        </div>
                        <div className="bh-validation" related="tbxPostCode"></div>
                    </div>
                    <div class="bh-login-success" style={{ display: 'none' }}></div>
                    <div class="bh-login-error" style={{ display: 'none' }}></div>
                </div>

                <div class="row end-of-form">

                    <div class="large-12 medium-12 small-12 columns">
                        <input onClick={handleSubmit} id="btnSendRequest" class="right button tiny" type="button" value="SUBMIT" />
                    </div>

                </div>

            </form>
        </div>
    );
};

const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({  }, dispatch)
);

export default widthConnect(AdminPage);