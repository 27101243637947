import React, { useEffect } from 'react';
import HeaderLayout from '../../components/Layout/Header';
import { Tabs, Select, Skeleton, Space } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdFavorite } from "react-icons/md";
import { Link } from 'react-router-dom';
import {ModalCustom} from '../../components/ModalCustom'

const { Option } = Select;

function HomePage(props) {
    const itemsList = (props?.tripadvisor?.listRestoran || []).map((el) => ({ title: el.name, content: 'Выставка в Москве', image: (el?.photo || 'https://via.placeholder.com/150'), link: '/category/categoryName/detail1', location: el.vicinity }))

    console.log('loading', props.tripadvisor.loading);

    useEffect(() => {
        // const awaitRequestLists = setInterval(() => {
        // console.log(123);
        if (props?.location?.lat && props?.location?.lon) {
            props.getListRestoran()
            props.getListAttractions()
            // clearTimeout(awaitRequestLists)
        }
        // }, 1000)
        // console.log(props);
    }, [props?.location?.lat, props?.location?.lon]);

    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        {
            key: 'All',
            label: 'All',
            children: <All items={itemsList} props={props} type='all'>Content of Tab Paneasfas 1</All>,
        },
        {
            key: 'Attractions',
            label: 'Attractions',
            children: <Tab items={props.tripadvisor.listAttractions} props={props} type='attractions'>Content of Tab Pane 2</Tab>,
        },
        {
            key: 'Restaurants',
            label: 'Restaurants',
            children: <Tab items={props.tripadvisor.listRestoran} props={props} type='restaurants'>Content of Tab Pane 3</Tab>,
        },
    ];

    if (!props.tripadvisor.loading) {
        return (
            <div className='homePage'>
                <HeaderLayout title={'Your Trawell '} isSelects={true} />
                <Tabs defaultActiveKey="Attractions" centered items={items} onChange={onChange} />
            </div>
        )
    }

    return (
        <>
            <div className='homePage'>
                <HeaderLayout title={'Your Trawell '} isSelects={true} />
                <Tabs defaultActiveKey="All" centered items={items} onChange={onChange} />
            </div>
        </>
    );
}


const All = ({ children, items, type, lat, lon, props }) => {

    return (
        <div>
          
        </div>
    );
}

const Tab = ({ items, props, type }) => {


    if (items.length === 0) {
        return (
            <>
                {[...Array(5)].map((_, index) => (
                        <div className='tabElement' key={index}>
                            <div className='tabElement_top'>
                                <Skeleton.Image active={true} size={'Large'} style={{ width: '100%', height: '100%' }} rootClassName='skeletonCustom' />
                            </div>
                            <div className='tabElement_bottom'>
                                <Skeleton active={true}>
                                </Skeleton>
                            </div>
                        </div>
                ))}
            </>
        )
    }


    return <>
        {
            (items || []).map((el, index) => {

                return (
                    <Link to={`/${type}/${el.place_id}`}>
                        <div className='tabElement' key={index}>
                            <div className='tabElement_top'>
                                <img src={(el?.photo || 'https://via.placeholder.com/150')} alt="" />
                                <div className='tabElement_top_info'>
                                    <div className='tabElement_top_favorite'>
                                        <span>
                                            <MdFavorite />
                                        </span>
                                    </div>
                                    <div className='tabElement_top_price'>
                                        <span>99$</span>
                                    </div>
                                </div>
                            </div>
                            <div className='tabElement_bottom'>
                                <div className='tabElement_bottom_name'><h3>{el.name}</h3></div>
                                <div className='tabElement_bottom_description'>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci quaerat magni
                                    </p>
                                </div>
                                <div className='tabElement_bottom_location'>
                                    <p>
                                        {el.vicinity}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Link>
                )
            })
        }
    </>


}

const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ }, dispatch)
);

export default widthConnect(HomePage);
