import React, { useState, useEffect } from 'react';
import { Button, Modal, ConfigProvider } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { use } from 'react';



const ModalCustomLogin = ({ customer }) => {
    const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);

    const handleOk = () => {
        setIsCustomModalOpen(false);
        localStorage.setItem('modalShown', 'true');
    };
    const handleCancel = () => {
        setIsCustomModalOpen(false);
    };
    useEffect(() => {
        const modalShown = localStorage.getItem('modalShown');
        if (modalShown === null) {
            setIsCustomModalOpen(true);
        }
    }, []);
    return (
        <ConfigProvider
            theme={{
                token: {
                    padding: 0,
                    paddingLG: 0,
                    margin: 0,
                    marginLG: 0,

                },
                components: {
                    Modal: {
                        padding: 0,
                        margin: 0,
                    },
                },
            }}

        >
            <Modal

                title={<div style={{ color: 'white', backgroundColor: '#16213e', width: '100%', padding: '10px 10px', borderRadius: '8px 8px 0 0' }}>Информация</div>}
                style={{ backgroundColor: '#1a1a2e', padding: 0, }}
                bodyStyle={{ padding: 10 }}
                footerStyle={{ padding: 10 }}
                open={isCustomModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                footer={[

                    <div style={{ display: 'flex', justifyContent: 'end', padding: '10px 10px', borderRadius: '0 0 8px 8px' }}>
                        <Button key="submit" type="primary" onClick={handleOk} style={{ backgroundColor: '#9D968C', color: 'white', borderRadius: '8px' }}>
                            OK
                        </Button>
                    </div>,

                ]}
            >

                <p>Ваш вход совершен успешно</p>
                <p>{customer.data.first_name} {customer.data.last_name}</p>
                <p><h6>Приветствуем Вас в кабинете Brevan Hovard!</h6></p>
            </Modal>
        </ConfigProvider>
    );
};

const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({}, dispatch)
);
export default widthConnect(ModalCustomLogin);