import { useEffect, useState } from 'react';
import { Breadcrumb, Layout, Menu, Avatar, Popconfirm, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { useLocation, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  UserOutlined,
  DollarOutlined,
  BarChartOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FaQuestionCircle } from "react-icons/fa";
import { logout, logoutUser } from '../../actions/customer';
import { useDispatch } from 'react-redux';






const { Header, Content, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}


const items = [
  getItem('Мой профиль', '/admin/user/profile', <Link to="/admin/user/profile"> <UserOutlined /> </Link>),
  getItem('Ввод/вывод', '/admin/finance', <Link to="/admin/finance"> <DollarOutlined /> </Link>),
  getItem('Активы', '/admin/assets', <Link to="/admin/assets"> <BarChartOutlined /> </Link>),
];



function AdminLayoutCustom({ children, customer, logoutUser }) {
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const [path, setPath] = useState('');

  const location = useLocation();

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
  };

  useEffect(() => {
    setPath(location.pathname)
  }, [location]);


  const itemss = [
    {
      label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><FaQuestionCircle style={{ marginRight: '10px' }} /> Поддержка</div>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: <Button onClick={handleLogout} type="primary" danger style={{ width: '100%' }}>Выйти</Button>,
      key: '1',
    },
  ];



  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          padding: '0 20px', 
          backgroundColor: '#141c25', 
          color: '#ffff'
        }}
      >
        <div className="logo" >
          {/* <svg width="333" height="20" viewBox="0 0 333 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.00585938 19.1126L1.50461 18.9161C3.10328 18.6704 3.30312 18.2281 3.30312 17.0488V2.9456C3.30312 1.7171 3.15324 1.27484 1.50461 1.07827L0.00585938 0.881714V0.439453H8.8485C12.5455 0.439453 16.2423 1.4714 16.2423 4.86206C16.2423 7.56476 13.7944 8.98983 10.4472 9.43209V9.53036C14.4438 9.77606 17.1416 11.1028 17.1416 14.1986C17.1416 17.7368 13.9942 19.5549 9.14825 19.5549H0.00585938V19.1126ZM8.49879 9.28466C11.6462 9.28466 12.8452 7.81046 12.8452 4.86206C12.8452 1.66795 11.4463 0.930854 8.54875 0.930854H6.45049V9.28466H8.49879ZM6.45049 17.3928C6.45049 18.5721 6.95007 19.0635 8.24899 19.0635H9.04833C12.0458 19.0635 13.4946 17.6876 13.4946 14.1986C13.4946 10.9554 11.6462 9.77606 8.44883 9.77606H6.45049V17.3928Z" fill="#141c25" />
            <path d="M42.071 19.6041C40.2726 19.6041 40.0727 19.211 39.4232 17.9825L35.5266 10.5132C35.2767 10.5623 35.0768 10.5623 34.877 10.5623C34.0777 10.5623 33.3284 10.5132 32.7288 10.5132V17.0979C32.7288 18.3264 32.8786 18.7688 34.5274 18.9653L36.0261 19.1618V19.6041C34.3275 19.6041 32.8786 19.5549 31.1802 19.5549C29.4815 19.5549 28.0328 19.6041 26.2842 19.6041V19.1618L27.7829 18.9653C29.3817 18.7195 29.5814 18.2773 29.5814 17.0979V2.9456C29.5814 1.7171 29.4315 1.27484 27.7829 1.07827L26.2842 0.881714V0.439453H34.7271C39.823 0.439453 43.0702 1.81538 43.0702 5.45174C43.0702 8.20358 40.6223 9.5795 38.3242 10.0218C38.6739 10.464 39.1235 11.0537 39.6231 11.9382L42.2209 16.5574C43.1202 18.179 43.4699 18.6704 44.7688 18.9653L45.818 19.211V19.6533H42.071V19.6041ZM32.7288 9.97263C33.5282 10.0218 34.1777 10.0218 34.927 10.0218C38.0744 10.0218 39.6731 8.74413 39.6731 5.50088C39.6731 1.9628 38.0245 0.881714 34.7271 0.881714H32.7288V9.97263Z" fill="#141c25" />
            <path d="M61.7053 9.43209C63.5537 9.43209 64.1533 8.20358 65.1024 5.20604H65.602C65.552 6.68024 65.552 8.20358 65.552 9.67779C65.552 11.152 65.552 12.6754 65.602 14.1495H65.1024C64.0533 10.9554 63.5537 9.87434 61.7053 9.87434H59.607V17.3436C59.607 18.523 60.1066 19.0144 61.4055 19.0144H63.254C66.6012 19.0144 67.4005 17.7859 69.299 12.8228H69.8984L69.5987 19.5058H53.2124V19.0635L54.7112 18.867C56.3098 18.6213 56.5096 18.179 56.5096 16.9996V2.9456C56.5096 1.7171 56.3598 1.27484 54.7112 1.07827L53.2124 0.881714V0.439453H68.7494L69.0491 6.58196H68.4497C66.7511 2.25764 65.9018 0.979994 63.1041 0.979994H59.657V9.48123H61.7053V9.43209Z" fill="#141c25" />
            <path d="M80.7389 3.9284C79.9396 1.76624 79.3401 1.17655 78.0912 1.02913L77.042 0.881714V0.439453C78.6906 0.488594 80.3392 0.488594 81.9878 0.488594C83.6365 0.488594 85.2352 0.488594 86.9337 0.439453V0.881714L85.2851 1.07827C83.7365 1.27484 83.5866 2.06108 84.0362 3.28958L88.8322 16.3609L93.0287 5.10776C94.0777 2.35591 93.828 1.42225 91.8797 1.07827L90.4308 0.881714V0.439453C91.7298 0.488594 92.9787 0.488594 94.2277 0.488594C95.5266 0.488594 96.7755 0.488594 98.0744 0.439453V0.881714L96.9754 1.17655C95.3268 1.52054 94.7273 2.40505 93.5283 5.4026L89.2818 16.4591L88.1327 19.7024H86.7838L80.7389 3.9284Z" fill="#141c25" />
            <path d="M115.56 19.5559C113.961 19.5559 112.562 19.605 110.963 19.605V19.1628L112.612 18.9663C114.161 18.7696 114.111 17.9834 113.611 16.7549L112.312 13.4134H104.769L104.169 14.9368C103.07 17.7377 103.07 18.5731 105.018 18.917L106.467 19.1628V19.605C105.218 19.605 104.269 19.5559 103.02 19.5559C101.771 19.5559 100.622 19.605 99.373 19.605V19.1628L100.322 18.917C101.821 18.5239 102.421 17.7378 103.57 14.691L107.966 3.53621L109.115 0.292969H110.614L116.859 16.1161C117.708 18.2782 118.058 18.8679 119.256 19.0154L120.256 19.1628V19.605C118.557 19.605 117.208 19.5559 115.56 19.5559ZM104.968 12.7745H112.063L108.515 3.58535L104.968 12.7745Z" fill="#141c25" />
            <path d="M130.047 2.74904V15.5255C130.047 17.9825 130.546 18.6704 132.095 18.9161L133.294 19.1126V19.5549C131.995 19.5549 130.996 19.5058 129.698 19.5058C128.398 19.5058 127.349 19.5549 126.101 19.5549V19.1126L127.299 18.9161C128.898 18.6704 129.398 17.9333 129.398 15.5255V2.9456C129.398 1.7171 129.248 1.27484 127.599 1.07827L126.101 0.881714V0.439453H132.345L142.787 15.5746V4.46894C142.787 2.01194 142.287 1.32398 140.688 1.07827L139.49 0.881714V0.439453C140.788 0.439453 141.837 0.488594 143.087 0.488594C144.385 0.488594 145.384 0.439453 146.683 0.439453V0.881714L145.484 1.07827C143.935 1.32398 143.435 2.01194 143.435 4.46894V19.6533H141.637L130.047 2.74904Z" fill="#141c25" />
            <path d="M189.848 2.9456C189.848 1.7171 189.698 1.27484 188.049 1.07827L186.551 0.881714V0.439453C188.249 0.439453 189.698 0.488594 191.447 0.488594C193.145 0.488594 194.594 0.439453 196.293 0.439453V0.881714L194.794 1.07827C193.195 1.32398 192.995 1.76624 192.995 2.9456V17.0488C192.995 18.2773 193.145 18.7195 194.794 18.9161L196.293 19.1126V19.5549C194.594 19.5549 193.145 19.5058 191.447 19.5058C189.748 19.5058 188.299 19.5549 186.551 19.5549V19.1126L188.049 18.9161C189.648 18.6704 189.848 18.2281 189.848 17.0488V10.0709H180.755V17.0488C180.755 18.2773 180.905 18.7195 182.554 18.9161L184.002 19.1126V19.5549C182.304 19.5549 180.855 19.5058 179.156 19.5058C177.458 19.5058 176.009 19.5549 174.311 19.5549V19.1126L175.809 18.9161C177.408 18.6704 177.608 18.2281 177.608 17.0488V2.9456C177.608 1.7171 177.458 1.27484 175.809 1.07827L174.311 0.881714V0.439453C176.009 0.439453 177.458 0.488594 179.156 0.488594C180.855 0.488594 182.304 0.439453 184.002 0.439453V0.881714L182.554 1.07827C180.955 1.32398 180.755 1.76624 180.755 2.9456V9.48123H189.848V2.9456Z" fill="#141c25" />
            <path d="M212.73 20C207.384 20 202.987 16.0688 202.987 10.0246C203.037 3.9312 207.384 0 212.73 0C218.075 0 222.422 3.9312 222.422 10.0246C222.422 16.0688 218.075 20 212.73 20ZM212.73 19.6069C217.076 19.6069 218.825 15.2825 218.825 10.0246C218.825 4.76659 217.126 0.44226 212.73 0.44226C208.233 0.44226 206.585 4.76659 206.585 10.0246C206.585 15.2825 208.283 19.6069 212.73 19.6069Z" fill="#141c25" />
            <path d="M231.463 3.88019C230.763 1.81631 230.214 1.17749 228.915 1.03007L227.866 0.88265V0.440389C229.515 0.489529 231.163 0.489529 232.812 0.489529C234.461 0.489529 236.059 0.489529 237.758 0.440389V0.88265L236.109 1.07921C234.511 1.27577 234.311 2.20943 234.661 3.29051L238.507 15.7721L243.004 0.292969H244.502L249.698 15.7721L252.895 5.15784C253.844 2.06201 253.194 1.42319 251.347 1.07921L250.097 0.83351V0.440389C251.397 0.489529 252.645 0.489529 253.944 0.489529C255.193 0.489529 256.493 0.489529 257.741 0.440389V0.88265L256.741 1.12835C255.043 1.47232 254.394 2.40599 253.494 5.40354L250.197 16.2144L249.248 19.7524H247.8L242.504 4.32245L239.057 16.1161L238.108 19.7033H236.658L231.463 3.88019Z" fill="#141c25" />
            <path d="M276.226 19.5559C274.628 19.5559 273.229 19.605 271.63 19.605V19.1628L273.279 18.9663C274.828 18.7696 274.778 17.9834 274.278 16.7549L272.979 13.4134H265.436L264.836 14.9368C263.737 17.7377 263.737 18.5731 265.686 18.917L267.134 19.1628V19.605C265.885 19.605 264.936 19.5559 263.687 19.5559C262.437 19.5559 261.289 19.605 260.04 19.605V19.1628L260.989 18.917C262.487 18.5239 263.087 17.7378 264.236 14.691L268.633 3.53621L269.782 0.292969H271.28L277.525 16.1161C278.375 18.2782 278.725 18.8679 279.923 19.0154L280.922 19.1628V19.605C279.173 19.605 277.825 19.5559 276.226 19.5559ZM265.636 12.7745H272.729L269.182 3.58535L265.636 12.7745Z" fill="#141c25" />
            <path d="M302.454 19.6041C300.656 19.6041 300.456 19.211 299.807 17.9825L295.91 10.5132C295.66 10.5623 295.46 10.5623 295.26 10.5623C294.461 10.5623 293.711 10.5132 293.112 10.5132V17.0979C293.112 18.3264 293.262 18.7688 294.911 18.9653L296.41 19.1618V19.6041C294.711 19.6041 293.262 19.5549 291.564 19.5549C289.865 19.5549 288.417 19.6041 286.668 19.6041V19.1618L288.167 18.9653C289.765 18.7195 289.965 18.2773 289.965 17.0979V2.9456C289.965 1.7171 289.815 1.27484 288.167 1.07827L286.668 0.881714V0.439453H295.111C300.207 0.439453 303.454 1.81538 303.454 5.45174C303.454 8.20358 301.006 9.5795 298.707 10.0218C299.057 10.464 299.507 11.0537 300.007 11.9382L302.604 16.5574C303.504 18.179 303.853 18.6704 305.153 18.9653L306.201 19.211V19.6533H302.454V19.6041ZM293.112 9.97263C293.911 10.0218 294.561 10.0218 295.31 10.0218C298.458 10.0218 300.057 8.74413 300.057 5.50088C300.057 1.9628 298.408 0.881714 295.111 0.881714H293.112V9.97263Z" fill="#141c25" />
            <path d="M313.094 19.1126L314.593 18.9161C316.192 18.6704 316.392 18.2281 316.392 17.0487V2.9456C316.392 1.7171 316.242 1.27484 314.593 1.07827L313.094 0.881714V0.439453H322.037C327.482 0.439453 332.478 3.14215 332.478 10.0218C332.478 16.9014 327.482 19.6041 321.987 19.6041H313.044V19.1126H313.094ZM321.987 19.0635C326.383 19.0635 328.832 16.5082 328.832 10.0218C328.832 3.437 326.283 0.930854 321.987 0.930854H319.489V17.3927C319.489 18.5721 319.989 19.0635 321.288 19.0635H321.987Z" fill="#141c25" />
          </svg> */}
                 <svg width="333" height="20" viewBox="0 0 333 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.00585938 19.1126L1.50461 18.9161C3.10328 18.6704 3.30312 18.2281 3.30312 17.0488V2.9456C3.30312 1.7171 3.15324 1.27484 1.50461 1.07827L0.00585938 0.881714V0.439453H8.8485C12.5455 0.439453 16.2423 1.4714 16.2423 4.86206C16.2423 7.56476 13.7944 8.98983 10.4472 9.43209V9.53036C14.4438 9.77606 17.1416 11.1028 17.1416 14.1986C17.1416 17.7368 13.9942 19.5549 9.14825 19.5549H0.00585938V19.1126ZM8.49879 9.28466C11.6462 9.28466 12.8452 7.81046 12.8452 4.86206C12.8452 1.66795 11.4463 0.930854 8.54875 0.930854H6.45049V9.28466H8.49879ZM6.45049 17.3928C6.45049 18.5721 6.95007 19.0635 8.24899 19.0635H9.04833C12.0458 19.0635 13.4946 17.6876 13.4946 14.1986C13.4946 10.9554 11.6462 9.77606 8.44883 9.77606H6.45049V17.3928Z" fill="#ffff" />
            <path d="M42.071 19.6041C40.2726 19.6041 40.0727 19.211 39.4232 17.9825L35.5266 10.5132C35.2767 10.5623 35.0768 10.5623 34.877 10.5623C34.0777 10.5623 33.3284 10.5132 32.7288 10.5132V17.0979C32.7288 18.3264 32.8786 18.7688 34.5274 18.9653L36.0261 19.1618V19.6041C34.3275 19.6041 32.8786 19.5549 31.1802 19.5549C29.4815 19.5549 28.0328 19.6041 26.2842 19.6041V19.1618L27.7829 18.9653C29.3817 18.7195 29.5814 18.2773 29.5814 17.0979V2.9456C29.5814 1.7171 29.4315 1.27484 27.7829 1.07827L26.2842 0.881714V0.439453H34.7271C39.823 0.439453 43.0702 1.81538 43.0702 5.45174C43.0702 8.20358 40.6223 9.5795 38.3242 10.0218C38.6739 10.464 39.1235 11.0537 39.6231 11.9382L42.2209 16.5574C43.1202 18.179 43.4699 18.6704 44.7688 18.9653L45.818 19.211V19.6533H42.071V19.6041ZM32.7288 9.97263C33.5282 10.0218 34.1777 10.0218 34.927 10.0218C38.0744 10.0218 39.6731 8.74413 39.6731 5.50088C39.6731 1.9628 38.0245 0.881714 34.7271 0.881714H32.7288V9.97263Z" fill="#ffff" />
            <path d="M61.7053 9.43209C63.5537 9.43209 64.1533 8.20358 65.1024 5.20604H65.602C65.552 6.68024 65.552 8.20358 65.552 9.67779C65.552 11.152 65.552 12.6754 65.602 14.1495H65.1024C64.0533 10.9554 63.5537 9.87434 61.7053 9.87434H59.607V17.3436C59.607 18.523 60.1066 19.0144 61.4055 19.0144H63.254C66.6012 19.0144 67.4005 17.7859 69.299 12.8228H69.8984L69.5987 19.5058H53.2124V19.0635L54.7112 18.867C56.3098 18.6213 56.5096 18.179 56.5096 16.9996V2.9456C56.5096 1.7171 56.3598 1.27484 54.7112 1.07827L53.2124 0.881714V0.439453H68.7494L69.0491 6.58196H68.4497C66.7511 2.25764 65.9018 0.979994 63.1041 0.979994H59.657V9.48123H61.7053V9.43209Z" fill="#ffff" />
            <path d="M80.7389 3.9284C79.9396 1.76624 79.3401 1.17655 78.0912 1.02913L77.042 0.881714V0.439453C78.6906 0.488594 80.3392 0.488594 81.9878 0.488594C83.6365 0.488594 85.2352 0.488594 86.9337 0.439453V0.881714L85.2851 1.07827C83.7365 1.27484 83.5866 2.06108 84.0362 3.28958L88.8322 16.3609L93.0287 5.10776C94.0777 2.35591 93.828 1.42225 91.8797 1.07827L90.4308 0.881714V0.439453C91.7298 0.488594 92.9787 0.488594 94.2277 0.488594C95.5266 0.488594 96.7755 0.488594 98.0744 0.439453V0.881714L96.9754 1.17655C95.3268 1.52054 94.7273 2.40505 93.5283 5.4026L89.2818 16.4591L88.1327 19.7024H86.7838L80.7389 3.9284Z" fill="#ffff" />
            <path d="M115.56 19.5559C113.961 19.5559 112.562 19.605 110.963 19.605V19.1628L112.612 18.9663C114.161 18.7696 114.111 17.9834 113.611 16.7549L112.312 13.4134H104.769L104.169 14.9368C103.07 17.7377 103.07 18.5731 105.018 18.917L106.467 19.1628V19.605C105.218 19.605 104.269 19.5559 103.02 19.5559C101.771 19.5559 100.622 19.605 99.373 19.605V19.1628L100.322 18.917C101.821 18.5239 102.421 17.7378 103.57 14.691L107.966 3.53621L109.115 0.292969H110.614L116.859 16.1161C117.708 18.2782 118.058 18.8679 119.256 19.0154L120.256 19.1628V19.605C118.557 19.605 117.208 19.5559 115.56 19.5559ZM104.968 12.7745H112.063L108.515 3.58535L104.968 12.7745Z" fill="#ffff" />
            <path d="M130.047 2.74904V15.5255C130.047 17.9825 130.546 18.6704 132.095 18.9161L133.294 19.1126V19.5549C131.995 19.5549 130.996 19.5058 129.698 19.5058C128.398 19.5058 127.349 19.5549 126.101 19.5549V19.1126L127.299 18.9161C128.898 18.6704 129.398 17.9333 129.398 15.5255V2.9456C129.398 1.7171 129.248 1.27484 127.599 1.07827L126.101 0.881714V0.439453H132.345L142.787 15.5746V4.46894C142.787 2.01194 142.287 1.32398 140.688 1.07827L139.49 0.881714V0.439453C140.788 0.439453 141.837 0.488594 143.087 0.488594C144.385 0.488594 145.384 0.439453 146.683 0.439453V0.881714L145.484 1.07827C143.935 1.32398 143.435 2.01194 143.435 4.46894V19.6533H141.637L130.047 2.74904Z" fill="#ffff" />
            <path d="M189.848 2.9456C189.848 1.7171 189.698 1.27484 188.049 1.07827L186.551 0.881714V0.439453C188.249 0.439453 189.698 0.488594 191.447 0.488594C193.145 0.488594 194.594 0.439453 196.293 0.439453V0.881714L194.794 1.07827C193.195 1.32398 192.995 1.76624 192.995 2.9456V17.0488C192.995 18.2773 193.145 18.7195 194.794 18.9161L196.293 19.1126V19.5549C194.594 19.5549 193.145 19.5058 191.447 19.5058C189.748 19.5058 188.299 19.5549 186.551 19.5549V19.1126L188.049 18.9161C189.648 18.6704 189.848 18.2281 189.848 17.0488V10.0709H180.755V17.0488C180.755 18.2773 180.905 18.7195 182.554 18.9161L184.002 19.1126V19.5549C182.304 19.5549 180.855 19.5058 179.156 19.5058C177.458 19.5058 176.009 19.5549 174.311 19.5549V19.1126L175.809 18.9161C177.408 18.6704 177.608 18.2281 177.608 17.0488V2.9456C177.608 1.7171 177.458 1.27484 175.809 1.07827L174.311 0.881714V0.439453C176.009 0.439453 177.458 0.488594 179.156 0.488594C180.855 0.488594 182.304 0.439453 184.002 0.439453V0.881714L182.554 1.07827C180.955 1.32398 180.755 1.76624 180.755 2.9456V9.48123H189.848V2.9456Z" fill="#ffff" />
            <path d="M212.73 20C207.384 20 202.987 16.0688 202.987 10.0246C203.037 3.9312 207.384 0 212.73 0C218.075 0 222.422 3.9312 222.422 10.0246C222.422 16.0688 218.075 20 212.73 20ZM212.73 19.6069C217.076 19.6069 218.825 15.2825 218.825 10.0246C218.825 4.76659 217.126 0.44226 212.73 0.44226C208.233 0.44226 206.585 4.76659 206.585 10.0246C206.585 15.2825 208.283 19.6069 212.73 19.6069Z" fill="#ffff" />
            <path d="M231.463 3.88019C230.763 1.81631 230.214 1.17749 228.915 1.03007L227.866 0.88265V0.440389C229.515 0.489529 231.163 0.489529 232.812 0.489529C234.461 0.489529 236.059 0.489529 237.758 0.440389V0.88265L236.109 1.07921C234.511 1.27577 234.311 2.20943 234.661 3.29051L238.507 15.7721L243.004 0.292969H244.502L249.698 15.7721L252.895 5.15784C253.844 2.06201 253.194 1.42319 251.347 1.07921L250.097 0.83351V0.440389C251.397 0.489529 252.645 0.489529 253.944 0.489529C255.193 0.489529 256.493 0.489529 257.741 0.440389V0.88265L256.741 1.12835C255.043 1.47232 254.394 2.40599 253.494 5.40354L250.197 16.2144L249.248 19.7524H247.8L242.504 4.32245L239.057 16.1161L238.108 19.7033H236.658L231.463 3.88019Z" fill="#ffff" />
            <path d="M276.226 19.5559C274.628 19.5559 273.229 19.605 271.63 19.605V19.1628L273.279 18.9663C274.828 18.7696 274.778 17.9834 274.278 16.7549L272.979 13.4134H265.436L264.836 14.9368C263.737 17.7377 263.737 18.5731 265.686 18.917L267.134 19.1628V19.605C265.885 19.605 264.936 19.5559 263.687 19.5559C262.437 19.5559 261.289 19.605 260.04 19.605V19.1628L260.989 18.917C262.487 18.5239 263.087 17.7378 264.236 14.691L268.633 3.53621L269.782 0.292969H271.28L277.525 16.1161C278.375 18.2782 278.725 18.8679 279.923 19.0154L280.922 19.1628V19.605C279.173 19.605 277.825 19.5559 276.226 19.5559ZM265.636 12.7745H272.729L269.182 3.58535L265.636 12.7745Z" fill="#ffff" />
            <path d="M302.454 19.6041C300.656 19.6041 300.456 19.211 299.807 17.9825L295.91 10.5132C295.66 10.5623 295.46 10.5623 295.26 10.5623C294.461 10.5623 293.711 10.5132 293.112 10.5132V17.0979C293.112 18.3264 293.262 18.7688 294.911 18.9653L296.41 19.1618V19.6041C294.711 19.6041 293.262 19.5549 291.564 19.5549C289.865 19.5549 288.417 19.6041 286.668 19.6041V19.1618L288.167 18.9653C289.765 18.7195 289.965 18.2773 289.965 17.0979V2.9456C289.965 1.7171 289.815 1.27484 288.167 1.07827L286.668 0.881714V0.439453H295.111C300.207 0.439453 303.454 1.81538 303.454 5.45174C303.454 8.20358 301.006 9.5795 298.707 10.0218C299.057 10.464 299.507 11.0537 300.007 11.9382L302.604 16.5574C303.504 18.179 303.853 18.6704 305.153 18.9653L306.201 19.211V19.6533H302.454V19.6041ZM293.112 9.97263C293.911 10.0218 294.561 10.0218 295.31 10.0218C298.458 10.0218 300.057 8.74413 300.057 5.50088C300.057 1.9628 298.408 0.881714 295.111 0.881714H293.112V9.97263Z" fill="#ffff" />
            <path d="M313.094 19.1126L314.593 18.9161C316.192 18.6704 316.392 18.2281 316.392 17.0487V2.9456C316.392 1.7171 316.242 1.27484 314.593 1.07827L313.094 0.881714V0.439453H322.037C327.482 0.439453 332.478 3.14215 332.478 10.0218C332.478 16.9014 327.482 19.6041 321.987 19.6041H313.044V19.1126H313.094ZM321.987 19.0635C326.383 19.0635 328.832 16.5082 328.832 10.0218C328.832 3.437 326.283 0.930854 321.987 0.930854H319.489V17.3927C319.489 18.5721 319.989 19.0635 321.288 19.0635H321.987Z" fill="#ffff" />
          </svg>
        </div>

        <div className='HeaderRightMEnu'>

          <Dropdown
            menu={{
              items: itemss,
            }}
            trigger={['click']}
          >
            <Space onClick={(e) => e.preventDefault()}>
              <Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />
              <span>
                {customer.data.first_name} {customer.data.last_name}
              </span>
              <DownOutlined />
            </Space>
          </Dropdown>






        </div>
      </Header>
      <Layout className='layoutCustom'>
      <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['/admin/user/profile']}
            items={items}
            style={{ flex: 1, minWidth: 0, maxHeight: 50 }}
          />
        {/* <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <Menu theme="dark" selectedKeys={path} mode="inline" items={items} />
        </Sider> */}
        <Layout
          style={{
            padding: '24px 24px 24px',
          }}
        >
          <Content>
            <Outlet /> 
          </Content>
        </Layout>
      </Layout>
    </Layout>

  );
}



const widthConnect = connect(
  (state) => ({ ...state }),
  (dispatch) => bindActionCreators({ logout }, dispatch)
);

export default widthConnect(AdminLayoutCustom);
