import { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { registration } from '../../actions/customer'
import { useNavigate } from 'react-router-dom';
import { Input, message, Radio, InputNumber, DatePicker, Row, Button } from "antd";

const options = [
    {
        label: 'Ввод',
        value: 'Ввод',
    },
    {
        label: 'Вывод',
        value: 'Вывод',
    },
];

const FinancePage = ({ }) => {
    const [formData, setFormData] = useState({
        sum: 0,
        date: new Date(),
        type: 'Ввод',
    });

    console.log(formData);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);

    };


    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });

    };

    return (
        <div>
            <form>

                <Row className="row">
                    <div class="large-3 columns">
                       
                        <div class="bh-input">
                            <Radio.Group
                                block
                                options={options}
                                defaultValue={formData.type}
                                optionType="button"
                                buttonStyle="solid"
                                onChange={(e) => handleChange('type', e.target.value)}  
                                style={{ width: '100%', height: '40px' }}
                            />
                        </div>
                        <div class="bh-validation" related="tbxFirstName"></div>
                    </div>
                </Row>
                <div class="row">

                    <div class="large-6 columns">
                        <label>
                            <div class="bh-input-label">Сумма</div>
                        </label>
                        <div class="bh-input">
                            <InputNumber
                                name="sum"
                                min={0}
                                value={formData.sum}
                                onChange={(value) => handleChange('sum', value)}
                                style={{ width: '100%', height: '40px' }}
                            />
                            {/* <Input type="text" name="first_name" value={formData.sum} onChange={handleChange} placeholder="" className="bh-input required" id="tbxFirstName" /> */}
                            {/* <input type="text" placeholder="" class="bh-input required" id="tbxFirstName" /> */}
                        </div>
                        <div class="bh-validation" related="tbxFirstName"></div>
                    </div>

                    <div class="large-6 columns">
                        <label>
                            <div class="bh-input-label">Дата</div>
                        </label>
                        <div class="bh-input">
                            <DatePicker

                                showTime
                                onChange={(value) => handleChange('date', value)}
                                style={{ height: '40px', width: '100%' }}
                            />
                        </div>
                        <div class="bh-validation" related="tbxFirstName"></div>
                    </div>

                    <div class="bh-login-success" style={{ display: 'none' }}></div>
                    <div class="bh-login-error" style={{ display: 'none' }}></div>
                </div>

                <div class="row end-of-form">

                    <div class="large-12 medium-12 small-12 columns">
                        <Button  className="right" type="primary" onClick={handleSubmit}>SUBMIT</Button>
                        {/* <input onClick={handleSubmit} id="btnSendRequest" class="right button tiny" type="button" value="SUBMIT" /> */}
                    </div>

                </div>

            </form>
        </div>
    );
};

const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({}, dispatch)
);

export default widthConnect(FinancePage);