import { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { registration } from '../../actions/customer'
import { useNavigate } from 'react-router-dom';
import { activesList } from '../../actions/actives';
import ActiveBlock from '../../components/ActiveBlock';
import { Col, Input, message, Radio, InputNumber, DatePicker, Row, Button } from "antd";


const AssetsPage = ({ actives, }) => {
    const [formData, setFormData] = useState({
        sum: 0,
        date: new Date(),
        type: 'Ввод',
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('Все');
    const [selectedState, setSelectedState] = useState('Текущаяя');
    const [modalInfoData, setModalInfoData] = useState({})

    console.log(actives);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);

    };


    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });

    };

    const showModalInfo = (data) => {
        setIsModalInfoOpen(true);
        setModalInfoData(data)
    };

    const showModal = (data) => {
        setIsModalOpen(true);
        setModalInfoData(data)

    };

    useEffect(() => {
        if (actives.success) {
            // getCustomersInfo()
            activesList()
            // setIsModalOpen(false);
        }
    }, [actives.success])

    return (
        <div>
            <form>

                <div class="row">

                    <Row gutter={[30, 30]}>
                        {actives?.data
                            .map((data, index) => (
                                <Col span={8} key={index}>
                                    <ActiveBlock
                                        showModal={showModal}
                                        showModalInfo={showModalInfo}
                                        data={data}
                                    />
                                </Col>
                            ))}
                    </Row>

                </div>

              
            </form>
        </div>
    );
};

const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ activesList }, dispatch)
);

export default widthConnect(AssetsPage);