import { ConfigProvider } from 'antd';

export default function ThemeConfig({ children }) {

    return (
        <ConfigProvider
            theme={{
                token: {
                    // borderRadius: '90',
                    // controlHeight: '45',
                    // colorText: 'rgb(0, 0, 0)',
                    // colorBgElevated: 'rgb(32, 32, 32)',
                    // colorIcon: 'rgba(255, 255, 255, 0.25)',

                },
                components: {
                    Layout: {
                        headerBg: 'rgb(255, 255, 255)',
                        headerBg: 'rgb(255, 255, 255)',
                        siderBg: 'rgb(255, 255, 255)',
                    },
                    Menu: {
                        darkItemBg: 'rgb(255, 255, 255)',
                        darkItemColor: '#434C60',
                        darkItemHoverColor: '#434C60',
                        darkItemSelectedBg: '#141c25',
                    },
                    Select: {
                       
                    },
                    DatePicker: {
        
                    },
                    Collapse: {
                        
                    }
                },
            }}
        >
            {children}
        </ConfigProvider>
    )
}