import { useEffect, useState } from "react"
import { LeftOutlined } from '@ant-design/icons';
import { Button, message, Space, Input } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from './../../actions/customer'
import { useNavigate } from 'react-router-dom';


function RegisterPage({ login, customer }) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [disabled, setDisabled] = useState(true)
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleSubmit = (e) => {
        login(formData)
    };

    useEffect(() => {
        if (customer.error) {
            message.error(customer.error);
        }
    }, [customer.error]);

    useEffect(() => {
        if (formData.email.length && formData.password.length) {
            setDisabled(false)
        } else (
            setDisabled(true)
        )
    }, [formData]);

    useEffect(() => {
        localStorage.removeItem('modalShown'); // Удаляем ключ
    }
        , []);
        

    return (
        <body class="home page-template-default page page-id-16">

            <div class="ie-message"><img class="logo" src="/wp-content/uploads/2015/10/nav-logo.png" /><p>You are using an outdated version of Internet Explorer. To ensure security and accessibility, plese update your browser to view our website.</p><p><hr /></p><p>Brevan Howard is a global alternative asset manager, managing significant institutional assets across a number of diversified strategies.</p><p>Brevan Howard has offices in St Helier, London, Geneva, New York, Hong Kong, Tel Aviv and Washington.</p><p><b>Please send all investor relations/fund enquiries to:</b><br /><a href="mailto:ir@brevanhoward.com">ir@brevanhoward.com</a></p><p><b>Please send all other enquiries to:</b> <br /> <a href="mailto:enquiries@brevanhoward.com">enquiries@brevanhoward.com</a></p><p><b>Press enquiries to:</b><br />Peregrine Communications Group<br />Anthony Payne / Daniel Jason<br /><a href="mailto:enquiries@brevanhoward.com">daniel.jason@peregrinecommunications.com</a></p></div>

            <div id="footer-wrapper" style={{ marginBottom: '-188px' }}>
                <div class="inner-wrap">
                    <div id="container">
                        <header class="header" role="banner">

                            <div class="sticky contain-to-grid">
                                <nav class="top-bar" data-topbar="" data-options="scrolltop: false">
                                    <ul class="title-area title-area-li">

                                        <li class="name">
                                            <a class="mainlogo" href="/"><img src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" /></a>
                                        </li>
                                        <li class="toggle-topbar menu-icon">
                                            <a href="#"><span></span></a>
                                        </li>
                                    </ul>

                                    <section class="top-bar-section">
                                        <ul id="menu-main-menu-li" class="top-bar-menu right"> <li id="menu-item-98" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-16 current_page_item active menu-item-98"><a href="/">Overview</a></li>
                                            <li id="menu-item-96" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-96"><a href="https://portal.brevanhoward.com/documents/">Documents</a></li>
                                            <li id="menu-item-95" class="no-mobile menu-item menu-item-type-post_type menu-item-object-page menu-item-95"><a href="https://portal.brevanhoward.com/downloads/">Downloads</a></li>
                                            <li id="menu-item-117" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-117"><a href="https://portal.brevanhoward.com/account/">Account</a></li>
                                            <li id="menu-item-118" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-118"><a href="#">Logout</a></li>
                                        </ul>		</section></nav>
                            </div>


                        </header>




                        <div id="content" class="temp-portal">
                            <div id="inner-content" class="row">

                                <div class="large-12 medium-12 small-12 columns hero-image" style={{backgroundImage: 'none'}}>
                                    <img src="https://portal.brevanhoward.com/wp-content/uploads/2015/10/BH-banner-v3.jpg" />
                                </div>

                                <div class="large-4 large-push-8 medium-5 medium-push-7 small-12 columns right-content">
                                    <div class="large-12 small-12 columns full-block-container nopad-l login-block">
                                        <h4>INVESTOR LOGIN</h4>
                                        <form>
                                            <div class="row user-creds" style={{ display: 'block' }}>
                                                <div class="large-12 columns">
                                                    <label>
                                                        <div class="bh-input-label left">Email</div>
                                                    </label>
                                                    <label>
                                                        <div class="right"><a href="/register">Request Access</a></div>
                                                    </label>
                                                    <div class="bh-input">
                                                        <Input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="" className="bh-input required" id="tbxRequestEmail" />

                                                        {/* <input id="bh-username" type="email" placeholder="Enter Your Email" class="bh-input" tabindex="1" /> */}
                                                    </div>
                                                </div>
                                                <div class="large-12 columns">
                                                    <label>
                                                        <div class="bh-input-label left">Password</div>
                                                    </label>
                                                    <label>
                                                        <div class="right"><a id="bh-change-pw" href="/login/forgot-password">Forgot Password?</a></div>
                                                    </label>
                                                    <div class="bh-input">
                                                        <Input
                                                            type="password"
                                                            name="password" // Update the name attribute to "password"
                                                            value={formData.password} // Bind to formData.password
                                                            onChange={handleChange}
                                                            placeholder=""
                                                            className={`bh-input required ${errors.password ? 'error' : ''}`} // Optional: add error class for validation
                                                            id="tbxPassword" // Update the id for accessibility
                                                        />
                                                        {/* <input disabled={disabled} onClick={handleSubmit} id="bh-pw" type="password" placeholder="Enter Your Password" class="bh-input" tabindex="2" /> */}
                                                    </div>
                                                    {errors.password && <div class="bh-login-error">{errors.password}</div>}
                                                    <div class="bh-login-success"></div>
                                                </div>
                                            </div>

                                            <div class="row submit-login" style={{ display: 'block' }}>
                                                <div class="large-12 medium-12 small-12 columns">
                                                    <input disabled={disabled} onClick={handleSubmit} id="bh-login" type="button" value="SUBMIT" class="button right tiny fullwidth-button" tabindex="3" />
                                                </div>
                                            </div>
                                            <div class="row logged-in-info">
                                                <div class="large-12 medium-12 small-12 columns">
                                                    You are logged in as <span class="logged-in"></span>




                                                </div>
                                            </div>
                                        </form></div>

                                </div>

                                <div class="large-8 large-pull-4 medium-7 medium-pull-5 small-12 columns left-content">

                                    <div id="about" data-magellan-destination="about">
                                        <div class="large-12 medium-12 small-12 columns full-block-container nopad-l">
                                            <h4>ABOUT</h4>
                                            <h5>Founded in 2002, Brevan Howard is one of the world’s leading global macro absolute return managers.</h5>
                                            <div class="large-12 medium-12 small-12 columns nopad-l">
                                                <p>Brevan Howard manages assets for institutional investors around the globe including sovereign wealth funds, corporate and public pension plans, foundations and endowments. The group has over&nbsp;200 employees with offices in London, New York,&nbsp;Geneva,&nbsp;Jersey, Hong Kong, Washington and Singapore.</p>
                                                <p>Our investment approach, combines rigorous fundamental macro research with an uncompromising focus on risk management in order to express directional and relative value views with asymmetrical risk/reward characteristics.</p>
                                                <p>We believe that effective risk management is fundamental to delivering investment returns and view risk&nbsp;as the potential loss of capital, rather than simply the volatility of returns.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="bhlps" data-magellan-destination="bhlps">
                                        <div class="large-12 medium-12 small-12 columns full-block-container nopad-l">
                                            <h4>LIQUID PORTFOLIO STRATEGIES</h4>
                                            <div class="large-12 medium-12 small-12 columns nopad-l">
                                                <p>In 2015 Brevan Howard&nbsp;established a Liquid Portfolio Strategies group to design and manage differentiated liquid investment strategies. Further details can be found on the following dedicated website: <a href="http://www.bhlps.com">www.bhlps.com</a></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="locations" data-magellan-destination="locations">
                                        <div class="large-12 medium-12 small-12 columns full-block-container nopad-l">
                                            <h4>LOCATIONS</h4>
                                            <div class="large-6 medium-6 small-12 columns nopad-l">
                                                <ul class="accordion nopad-l" data-accordion="">
                                                    <li class="accordion-navigation">
                                                        <a href="#geneva" aria-expanded="false">GENEVA</a>
                                                        <div id="geneva" class="content nopad-l">
                                                            <p>Rue d'Italie 10<br />
                                                                Geneva<br />
                                                                1204<br />
                                                                Switzerland  <br />
                                                                <a target="_blank" href="https://goo.gl/maps/qa9ih9eNY8m">View on Google Maps</a> </p>
                                                            <a target="_blank" href="https://goo.gl/maps/qa9ih9eNY8m"><img class="mapimage" src="https://portal.brevanhoward.com/wp-content/uploads/2018/06/Geneva-2.png" /></a>
                                                        </div>
                                                    </li>
                                                    <li class="accordion-navigation">
                                                        <a href="#hongkong" aria-expanded="false">HONG KONG</a>
                                                        <div id="hongkong" class="content nopad-l">
                                                            <p>Unit 2601, 26/F, Two Chinachem Central<br />
                                                                26 Des Voeux Road Central<br />
                                                                Hong Kong <br />
                                                                <a target="_blank" href="https://www.google.com/maps/place/The+Executive+Centre+-+Two+Chinachem+Central+%7C+Serviced+%26+Virtual+Offices+and+Workspace/@22.2826916,114.154767,17z/data=!4m5!3m4!1s0x3404007cd1915555:0xd7ede1224b5b756d!8m2!3d22.2826867!4d114.1569557">View on Google Maps</a> </p>
                                                            <a target="_blank" href="https://www.google.com/maps/place/The+Executive+Centre+-+Two+Chinachem+Central+%7C+Serviced+%26+Virtual+Offices+and+Workspace/@22.2826916,114.154767,17z/data=!4m5!3m4!1s0x3404007cd1915555:0xd7ede1224b5b756d!8m2!3d22.2826867!4d114.1569557"><img class="mapimage" src="https://portal.brevanhoward.com/wp-content/uploads/2021/01/Hong-Kong-Office-1.png" /></a>
                                                        </div>
                                                    </li>
                                                    <li class="accordion-navigation">
                                                        <a href="#jersey" aria-expanded="false">JERSEY</a>
                                                        <div id="jersey" class="content nopad-l">
                                                            <p>&ZeroWidthSpace;37 Esplanade, 6th Floor<br />
                                                                St Helier<br />
                                                                JE2 3QA <br />
                                                                <a target="_blank" href="https://www.google.co.uk/maps/place/37+Esplanade,+JE2+3QE,+Jersey/data=!4m2!3m1!1s0x480c52a81354dcf1:0x679f2fcb6befc6c1?sa=X&amp;ved=0ahUKEwjot-Dhxe3KAhWJWRoKHSPeB9UQ8gEIHDAA ">View on Google Maps</a> </p>
                                                            <a target="_blank" href="https://www.google.co.uk/maps/place/37+Esplanade,+JE2+3QE,+Jersey/data=!4m2!3m1!1s0x480c52a81354dcf1:0x679f2fcb6befc6c1?sa=X&amp;ved=0ahUKEwjot-Dhxe3KAhWJWRoKHSPeB9UQ8gEIHDAA "><img class="mapimage" src="https://portal.brevanhoward.com/wp-content/uploads/2018/06/Jersey-2.png" /></a>
                                                        </div>
                                                    </li>
                                                    <li class="accordion-navigation">
                                                        <a href="#washington" aria-expanded="false">WASHINGTON</a>
                                                        <div id="washington" class="content nopad-l">
                                                            <p>1776 I Street NW<br />
                                                                Suite 250<br />
                                                                Washington<br />
                                                                DC 20006<br />
                                                                United States <br />
                                                                <a target="_blank" href="https://goo.gl/maps/Axr6epRSZ3z">View on Google Maps</a> </p>
                                                            <a target="_blank" href="https://goo.gl/maps/Axr6epRSZ3z"><img class="mapimage" src="https://portal.brevanhoward.com/wp-content/uploads/2018/06/Washington-2.png" /></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="large-6 medium-6 small-12 columns nopad-l">
                                                <ul class="accordion nopad-l" data-accordion="">
                                                    <li class="accordion-navigation">
                                                        <a href="#london" aria-expanded="false">LONDON</a>
                                                        <div id="london" class="content nopad-l">
                                                            <p>55 Baker Street<br />
                                                                London<br />
                                                                W1U 8EW<br />
                                                                United Kingdom <br />
                                                                <a target="_blank" href="https://www.google.co.uk/maps/place/51%C2%B031'06.1%22N+0%C2%B009'22.7%22W/@51.5183593,-0.1584944,17z/data=!4m2!3m1!1s0x0:0x0">View on Google Maps</a> </p>
                                                            <a target="_blank" href="https://www.google.co.uk/maps/place/51%C2%B031'06.1%22N+0%C2%B009'22.7%22W/@51.5183593,-0.1584944,17z/data=!4m2!3m1!1s0x0:0x0"><img class="mapimage" src="https://portal.brevanhoward.com/wp-content/uploads/2018/06/London-2.png" /></a>
                                                        </div>
                                                    </li>
                                                    <li class="accordion-navigation">
                                                        <a href="#newyork" aria-expanded="false">NEW YORK</a>
                                                        <div id="newyork" class="content nopad-l">
                                                            <p>590 Madison Avenue<br />
                                                                New York<br />
                                                                NY 10022<br />
                                                                United States <br />
                                                                <a target="_blank" href="https://www.google.co.uk/maps/place/590+Madison+Avenue,+New+York,+NY+10022,+USA/@40.76225,-73.974564,17z/data=!3m1!4b1!4m2!3m1!1s0x89c258fabf54092f:0x8d11c056349672be">View on Google Maps</a> </p>
                                                            <a target="_blank" href="https://www.google.co.uk/maps/place/590+Madison+Avenue,+New+York,+NY+10022,+USA/@40.76225,-73.974564,17z/data=!3m1!4b1!4m2!3m1!1s0x89c258fabf54092f:0x8d11c056349672be"><img class="mapimage" src="https://portal.brevanhoward.com/wp-content/uploads/2018/06/new-york-2.png" /></a>
                                                        </div>
                                                    </li>
                                                    <li class="accordion-navigation">
                                                        <a href="#singapore" aria-expanded="false">SINGAPORE</a>
                                                        <div id="singapore" class="content nopad-l">
                                                            <p>6 Temasek Boulevard<br />
                                                                Suntec Tower 4 #30-03<br />
                                                                Singapore 038986  <br />
                                                                <a target="_blank" href="https://www.google.co.uk/maps/search/6+Temasek+Boulevard,+Suntec+Tower+4+%2330-03,+Singapore+038986/@1.2942311,103.8571029,17z/data=!3m1!4b1">View on Google Maps</a> </p>
                                                            <a target="_blank" href="https://www.google.co.uk/maps/search/6+Temasek+Boulevard,+Suntec+Tower+4+%2330-03,+Singapore+038986/@1.2942311,103.8571029,17z/data=!3m1!4b1"><img class="mapimage" src="https://portal.brevanhoward.com/wp-content/uploads/2018/11/sing-2-right-size.jpg" /></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="contact" data-magellan-destination="contact">
                                        <div class="large-12 medium-12 small-12 columns full-block-container nopad-l">
                                            <h4>CONTACT </h4>
                                            <div class="large-7 medium-7 small-12 columns nopad-l">
                                                <h5>Investor Relations / Fund Enquiries: </h5>
                                                <p><a href="mailto:ir@brevanhoward.com">ir@brevanhoward.com</a> </p>
                                                <h5>All Other Enquiries: </h5>
                                                <p><a href="mailto:enquiries@brevanhoward.com ">enquiries@brevanhoward.com</a> <br />
                                                    +44 20 7022 6200</p>
                                            </div>
                                            <div class="large-5 medium-5 small-12 columns nopad-lr">
                                                <h5>Press Enquiries: </h5>
                                                <p>Peregrine Communications Group<br />
                                                    UK +44 20 3040 0874<br />
                                                    <a href="mailto:Bill.McIntosh@peregrinecommunications.com">Email Bill McIntosh</a> <br />
                                                    USA +1 917 970 8842<br />
                                                    <a href="mailto:Darius.Athill@peregrinecommunications.com">Email Darius Athill</a> <br /></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="push" style={{ height: '188px' }}></div>
            </div>

            <footer class="footer" role="contentinfo">
                <div id="inner-footer" class="row">

                    <div class="large-12 medium-12 small-12 columns">
                        <ul class="footerlinks inline-list">
                            <li><a href="/">OVERVIEW</a></li>
                            <li><a href="/login/request-access/">REQUEST ACCESS</a></li>
                            <li><a href="/disclaimer">DISCLAIMER</a></li>
                            <li><a href="/cookies-and-privacy">COOKIES &amp; PRIVACY POLICY</a></li>
                            <li><a href="/notices">NOTICES</a></li>
                            <li><a href="/sitemap">SITEMAP</a></li>

                        </ul>
                    </div>

                    <div class="large-12 medium-12 columns">

                        <p class="copyright">Data as 1 May 2020. Source: Brevan Howard Capital Management LP.<br />© 2024 Brevan Howard Asset Management LLP. <a class="peregrinelink" href="http://www.peregrinecommunications.com" target="_blank">Website by Peregrine Communications.</a></p>

                    </div>
                </div></footer>


            <div id="welcome-disclaimer" class="reveal-modal" data-options="close_on_background_click:false; close_on_esc:false;" data-reveal="">
                <img class="disclaimer-logo" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" />
                <h2>WELCOME</h2>
                <h5>Before Proceeding, please select your location.</h5>
                <a class="button tiny icon icon-right welcome-disclaimer-other">Other</a>
                <a class="button tiny icon icon-right welcome-disclaimer-usa">USA</a>
            </div>




            <div id="disclaimer-disagree" class="reveal-modal" data-options="close_on_background_click:false; close_on_esc:false;" data-reveal="">
                <img class="disclaimer-logo" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" />
                <h5>Thank you for visiting Brevan Howard.</h5>
                <p>For further information contact:</p>
                <h5>Investor Relations / Fund Enquiries: </h5>
                <p><a href="mailto: ir@brevanhoward.com">ir@brevanhoward.com</a> </p>
                <h5>All Other Enquiries: </h5>
                <p><a href="mailto: enquiries@brevanhoward.com ">enquiries@brevanhoward.com</a> </p>
                <h5>Press Enquiries: </h5>
                <p>Peregrine Communications Group<br />
                    UK +44 20 3040 0874<br />
                    <a href="mailto:darius.athill@peregrinecommunications.com">Email Darius Athill</a><br />
                    USA +1 646 274 1420<br />
                    <a href="mailto:max.hilton@peregrinecommunications.com">Email Max Hilton</a></p></div>



            <div id="usa-disclaimer" class="reveal-modal" data-options="close_on_background_click:false; close_on_esc:false;" data-reveal="">
                <img class="disclaimer-logo" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" />
                <h5>Access to the information contained in this website is restricted under applicable securities laws in the United States. The information contained in this website, therefore, generally is not available to United States persons.</h5>
                <p>For further information contact:</p>
                <h5>Investor Relations / Fund Enquiries: </h5>
                <p><a href="mailto: ir@brevanhoward.com">ir@brevanhoward.com</a> </p>
                <h5>All Other Enquiries: </h5>
                <p><a href="mailto: enquiries@brevanhoward.com ">enquiries@brevanhoward.com</a> </p>
                <h5>Press Enquiries: </h5>
                <p>Peregrine Communications Group<br />
                    UK +44 20 3040 0874<br />
                    <a href="mailto:darius.athill@peregrinecommunications.com">Email Darius Athill</a><br />
                    USA +1 646 274 1420<br />
                    <a href="mailto:max.hilton@peregrinecommunications.com">Email Max Hilton</a></p></div>



            <div id="cookies-and-privacy-disclaimer" class="reveal-modal" data-options="close_on_background_click:false; close_on_esc:false;" data-reveal="">
                <img class="disclaimer-logo" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" />
                <p><a href="#" class="cookies-back">&lt; Back</a></p>
                <h5>COOKIES AND PRIVACY POLICY</h5>
                <div class="cookies-terms-container">
                    <p class="threehundred">This Cookies and Privacy Policy describes how we handle your personal information on our site. It applies generally to our site. In addition to the privacy practices set out in this Cookies and Privacy Policy, we have <a href="https://www.brevanhoward.com/disclaimer/">Terms of Use</a> which incorporate the terms of this Cookies and Privacy Policy. Some words used in this Privacy Policy are defined in our Terms of Use.</p>
                    <p class="fivehundred">1. What are cookies?</p>
                    <p class="threehundred">Cookies are small text files which are placed on the computer of a website user to provide the website operator with information about use of our site. They are used to enable websites to function or to provide information to the owners of a website.</p>
                    <p class="threehundred">Some cookies are deleted when you close down your browser. These are known as session cookies. Others remain on your device until they expire or you delete them from your cache. These are known as persistent cookies and enable us to remember things about you as a returning visitor.</p>
                    <p class="threehundred">To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener">www.allaboutcookies.org</a>. Alternatively, you can search the internet for other independent information on cookies.</p>
                    <p class="fivehundred">2. Why do we use cookies?</p>
                    <p class="threehundred">In broad terms we use cookies on our site for the following purposes:</p>
                    <ul>
                        <li><strong>Analytical purposes</strong>: Analytical cookies allow us to recognise, measure and track users of our site in anonymous form. The information collected includes the number of visitors to our site, where visitors have come to our site from and the pages that they have visited. This helps us to improve the way our site works, for example, by determining whether site visitors can find information easily, or by identifying the aspects of websites that are of the most interest to them.</li>
                        <li><strong>Usage preferences</strong>: Some of the cookies on our site are activated when visitors to our sites make a choice about their usage of our site. The site then ‘remembers’ the settings preferences of the user concerned. This allows us to tailor aspects of our site to the individual user.</li>
                        <li><strong>Disclaimer</strong>: We use cookies on our site to record when a site visitor has seen a policy, such as this one, or provided consent, such as consent to our Disclaimer. This helps to improve the user’s experience of our site – for example, it avoids a user from repeatedly being asked to consent to the same terms.</li>
                        <li><strong>Session management</strong>: The software that runs our websites uses cookies for technical purposes needed by the internal workings of our servers. For instance, we use cookies to distribute requests among multiple servers, authenticate users and determine what features of our site they can access, verify the origin of requests, keep track of information about a user’s session and determine which options or pages to display in order for our site to function.</li>
                        <li><strong>Functional purposes</strong>: Functional purpose cookies store information that is needed by our applications to process and operate. For example, where transactions or requests within an application involve multiple workflow stages, cookies are used to store the information from each stage temporarily, in order to facilitate completion of the overall transaction or request.</li>
                    </ul>
                    <p class="fivehundred">3. Your cookie preferences</p>
                    <p class="threehundred">To make full use of our site, your computer or mobile device will need to accept cookies, as our site will not function properly without them. In addition, cookies are required in order to provide you with personalized features on our site.</p>
                    <p class="fivehundred">4. Third party cookies</p>
                    <p class="threehundred">When you visit our website, you may receive cookies that are set by third parties. These may include cookies set by Google. These cookies are used for the purposes described in the “Why do we use cookies?” section of this policy. We do not control the setting of these third party cookies, so we suggest you might wish to check the third party websites for more information about their use of cookies and how to manage them.</p>
                    <p class="fivehundred">5. Amending cookie preferences</p>
                    <p class="threehundred">If you wish to remove cookies set by our site from your browser in the future, you may delete them. The instructions for removing cookies from your computer or mobile device depend on the operating system and web browser you use. Please note, however, that withdrawing your agreement to the use of cookies on our site will impair the functionality of our site.</p>
                    <p class="fivehundred">6. Information we may collect from you</p>
                    <p class="threehundred">We may collect and process the following data about you in accordance with this Cookies and Privacy Policy:</p>
                    <ol type="A">
                        <li>information that you provide to us via our site;</li>
                        <li>if you contact us, we may keep a record of that correspondence and may record telephone calls for regulatory compliance and record keeping purposes; and</li>
                        <li>details of your visits to our site and the materials and information that you access.</li>
                    </ol>
                    <h4>7. Use of your information</h4>
                    <ol type="A">
                        <li>We may use data held about you in the following ways:<br />
                            to support your interaction with us, including to ensure that the content of our site is presented in the most effective manner for you and your computer, to provide you with information or products you may have requested through our site, to notify you about changes to our site and to ensure the ongoing accuracy and relevance of such data;</li>
                        <li>to carry out any security or other verification activities to ensure the proper operation of our site or to ensure compliance with our regulatory obligations;</li>
                        <li>for general internal research purposes, including to identify and analyse the regularity of the use of our site and the nature of our site’s users; and</li>
                        <li>to design products and services for your use.</li>
                    </ol>
                    <p class="threehundred">We may disclose your personal data to our affiliates, agents and contractors (including to parties located outside the European Economic Area) for the purposes stated above. We may also disclose your personal data to third parties (including to parties located outside the European Economic Area):</p>
                    <ol type="A">
                        <li>in the event that we sell or buy, or one or more of our affiliates sells or buys, any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets;</li>
                        <li>if we or our assets, or one or more of our affiliates or our affiliates assets’ are acquired by a third party, in which case personal data held by it about its clients may be one of the transferred assets;</li>
                        <li>if we, or one or more of our affiliates are under a duty to disclose or share your personal data in order to comply with any legal or regulatory obligation; or</li>
                        <li>in order to enforce or apply our Terms of Use and other agreements or to protect our rights, our customers or others.</li>
                    </ol>
                    <p class="threehundred">By using this site, you consent to processing of your personal data in accordance with our <a href="https://www.brevanhoward.com/disclaimer/">Terms of Use</a> and this Cookies and Privacy Policy and you warrant that all data provided by you is accurate.</p>
                </div>
            </div>

            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/vendor/foundation/js/vendor/jquery.js?ver=2.1.3"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/vendor/foundation/js/vendor/modernizr.js?ver=2.8.3"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/vendor/foundation/js/foundation.min.js?ver=4.9.4"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/js/js.cookie.js?ver=4.9.4"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/js/scripts.js?ver=4.9.4"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-includes/js/wp-embed.min.js?ver=4.9.4"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/js/overview.js?ver=4.9.4"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/js/bhlogin.js?ver=4.9.4"></script>


        </body>
    );
};


const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ login }, dispatch)
);

export default widthConnect(RegisterPage);
