import { React, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';


import HomePage from '../pages/HomePage';
import Dashboard from '../pages/Dashboard';
import Portfolio from '../pages/Portfolio'
import Table from '../pages/Table'

import AdminPage from '../pages/AdminPage'
import FinancePage from '../pages/AdminPage/FinancePage'
import AssetsPage from '../pages/AdminPage/AssetsPage'


import RegisterPage from '../pages/RegisterPage'
import LoginPage from '../pages/LoginPage'

import NotFound from '../pages/NotFound'
import ProtectedLoginRoute from './ProtectedLoginRoute';
import ProtectedRoute from './ProtectedRoute';
import ProtectedAdminRoute from './ProtectedAdminRoute';
import CreateProcessingActive from '../pages/CreateProcessingActive';
import ForgotPassword from '../pages/ForgotPassword';



export default function RootRoute({ isAuthenticated, role }) {

  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedLoginRoute element={<LoginPage />} isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/login"
        element={<ProtectedLoginRoute element={<LoginPage />} isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/register"
        element={<ProtectedLoginRoute element={<RegisterPage />} isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/login/forgot-password"
        element={<ProtectedLoginRoute element={<ForgotPassword />} isAuthenticated={isAuthenticated} />}
      />

      {/* Защищенный маршрут */}

      <Route
        path="/home"
        element={<ProtectedRoute element={<Dashboard />} isAuthenticated={isAuthenticated} />}
      />

      <Route
        path="/admin"
        element={<ProtectedAdminRoute isAuthenticated={isAuthenticated} role={role} />}
      >
        <Route path="user/profile" element={<AdminPage />} />
        <Route path="finance" element={<FinancePage />} />
        <Route path="assets" element={<AssetsPage />} />
      </Route>

      {/* <Route
        path="/admin/user/profile"
        element={<ProtectedAdminRoute element={<AdminPage />} isAuthenticated={isAuthenticated} role={role} />}
      /> */}

      <Route
        path="/active/create/:id"
        element={<ProtectedRoute element={<CreateProcessingActive />} isAuthenticated={isAuthenticated} />}
      />

      <Route
        path="/details"
        element={<ProtectedRoute element={<Portfolio />} isAuthenticated={isAuthenticated} />}
      />

      <Route
        path="/history"
        element={<ProtectedRoute element={<Table />} isAuthenticated={isAuthenticated} />}
      />

      {/* Маршрут для 404 */}
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
}



