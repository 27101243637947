import { Navigate } from 'react-router-dom';
import AdminLayoutCustom from '../components/Layout/adminLayout';
import ThemeConfig from '../theme/ThemeConfig';

export default function ProtectedAdminRoute({ element, isAuthenticated, role }) {
  return (isAuthenticated && (role === 10 ||  role === 100)) ? (
    <ThemeConfig>
      <AdminLayoutCustom>
        {element}
      </AdminLayoutCustom>
    </ThemeConfig>
  ) : (
    <>
      <Navigate to="/home" />
    </>
  )

}