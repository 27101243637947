import {

    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAIL,

    LOGOUT,

    REG_START,
    REG_SUCCESS,
    REG_FAIL,

    GET_CUSTOMERS_INFO_START,
    GET_CUSTOMERS_INFO_SUCCESS,
    GET_CUSTOMERS_INFO_FAIL

} from '../actions/customer';

const initialState = {
    token: null,
    isAuthenticated: false,
    statusResponseRegistration: false,
    data: null,
    loading: false,
    error: null,
};

const customerReducer = (state = initialState, action) => {
    let globalStore = null

    switch (action.type) {
        case GET_CUSTOMERS_INFO_START:
        case REG_START:
        case LOGIN_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOGOUT:
            return {
                ...initialState,
            };
        case GET_CUSTOMERS_INFO_SUCCESS:
            return {
                ...state,
                data: {
                    role: 10,
                    ...action.payload,
                },
                loading: false,
            };
        case LOGIN_SUCCESS:
            globalStore =
                JSON.parse(localStorage.getItem('globalStore')) || {};
            localStorage.setItem(
                'globalStore',
                JSON.stringify({
                    ...globalStore,
                    customer: {
                        ...globalStore?.customer,
                        token: action.payload.token,
                        data: {
                            role: 10,
                            ...action.payload.customer,
                        },
                        isAuthenticated: true,
                    },
                }),
            );

            return {
                ...state,
                token: action.payload.token,
                data: {
                    role: 10,
                    ...action.payload.customer,
                },
                isAuthenticated: true,
                loading: false,
            };
        case REG_SUCCESS:
            globalStore =
                JSON.parse(localStorage.getItem('globalStore')) || {};
            localStorage.setItem(
                'globalStore',
                JSON.stringify({
                    ...globalStore,
                    customer: {
                        ...globalStore?.customer,
                        statusResponseRegistration: true,
                    },
                }),
            );

            return {
                ...state,
                statusResponseRegistration: true,
                loading: false,
            };
        case GET_CUSTOMERS_INFO_FAIL:
        case LOGIN_FAIL:
        case REG_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default customerReducer;





