import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Radio, Button, Modal, Form, Input, Slider, InputNumber, Statistic, Select, Divider } from 'antd';
import { activesCategoryList } from '../../../actions/actives';
import moment from 'moment';


function ActiveBlock({ showModal, data, showModalInfo }) {

    const schemaColorActives = [
        'Equity', 'Credit', 'Multi-Asset'
    ]
    return (
        <div className='activeBlock'>
            <div className="activeBlock_Header">
                <div className='activeBlock_Header_Name'>Дата | время подтверждения</div>
                <div className='activeBlock_Header_Name'><b>{moment(data.date_start).format('DD.MM.YYYY | HH:mm')}</b></div>

            </div>
            <div className="activeBlock_Body">
                <>
                    <div className='activeBlock_Body_Item_Active'>
                        <div className='activeBlock_Body_Item_Stat'>
                            <div className='activeBlock_Body_Item_Stat--topBlock'>
                                <div>
                                    <h5>Вложенная сумма</h5>
                                </div>
                                <div>
                                    <h5>Процент</h5>
                                </div>
                            </div>
                            <div className='activeBlock_Body_Item_Stat--bottomBlock'>
                                <div>
                                    $<span style={{ fontWeight: 'bold', }}>{data.amount_income.toLocaleString('ru-RU', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}</span>
                                </div>
                                <div>
                                    <span style={{ fontWeight: 'bold', }}>{data.percent}</span>%
                                </div>
                            </div>
                        </div>
                        <div className='historyActiveBlock_Body_Item_Profit'>
                            <div>Накопленные дивиденды: ${data.amount_profit.toLocaleString('ru-RU', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </div>
                        <div className='activeBlock_Body_Item_Countdown-Time'>
                            <div>Завершен: {moment(data.date_end).format('DD.MM.YYYY | HH:mm')}</div>
                        </div>
                        <div className='activeBlock_Body_Item_Countdown'>
                            <div><b>Средства выведены</b></div>
                            <div><span style={{ color: '#166534' }}>В размере:</span> <b>${(data.amount_income + data.amount_profit).toLocaleString('ru-RU', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</b></div>
                            <div><span style={{ color: '#166534' }}>от:</span> <b>${(data.amount_income + data.amount_profit).toLocaleString('ru-RU', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</b></div>
                        </div>
                    </div>
                </>

            </div>

        </div>
    )
}

const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ activesCategoryList }, dispatch)
);

export default widthConnect(ActiveBlock);
